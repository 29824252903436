/* eslint-disable no-undef */
import {
  Box,
  CircularProgress,
  Container,
  IconButton,
  InputAdornment,
  Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import PropTypes from "prop-types";
import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { connect } from "react-redux";
import toaster from "../../modules/toaster";
import { checkUser } from "../../reducers/Auth";

// checkUser
const Auth = ({ checkUser, error, isPending }) => {
  const [username, setUsername] = useState("");
  const [pass, setPass] = useState("");
  const [captchToken, setCaptchToken] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  const handleLogin = async (username, pass, e) => {
    e.preventDefault();
    // if (!captchToken) {
    //   toaster.warning("Please verify reCAPTCHA first");
    // } else {
    try {
      const response = await checkUser(username, pass);
      toaster.success(`Welcome ${response.username}`);
    } catch (e) {
      console.error(e?.message || e || error);
      toaster.error(e?.message || e || error);
      setCaptchToken(null);
    }
    // }
  };

  const onCaptchChange = (value) => setCaptchToken(value);

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <section>
      <Container>
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100vh",
          }}
          onSubmit={(e) => handleLogin(username, pass, e)}
        >
          <Box
            sx={{
              width: "100%",
              maxWidth: "500px",
              boxShadow: "0 0 10px rgba(0,0,0,0.5)",
              borderRadius: "5px",
              padding: "20px",
              backgroundColor: "#fff",
              display: "flex",
              flexDirection: "column",
              alignItems: "stretch",
              justifyContent: "center",
              gap: "20px",
              position: "relative",
            }}
          >
            {isPending && (
              <Box
                sx={{
                  position: "absolute",
                  inset: 0,
                  backgroundColor: "rgba(0,0,0,0.2)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  zIndex: 1,
                }}
              >
                <CircularProgress />
              </Box>
            )}

            <Typography variant="h6" align="center" gutterBottom>
              Please Login to continue
            </Typography>

            <TextField
              label="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              name="username"
              placeholder="Enter Username"
              required
              variant="outlined"
            />

            <TextField
              label="Password"
              type={showPassword ? "text" : "password"}
              value={pass}
              onChange={(e) => setPass(e.target.value)}
              name="password"
              placeholder="Enter Password"
              required
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <ReCAPTCHA
              sitekey="6Lfpre4mAAAAACwc5DmDmhYdtegn4fw3qNIZ4KtH"
              onChange={onCaptchChange}
            />

            <Button type="submit" variant="outlined">
              Login
            </Button>
          </Box>
        </form>
      </Container>
    </section>
  );
};

const mapStateToProps = (state) => ({
  isPending: state.Auth.isPending,
  error: state.Auth.error,
  user: state.Auth.user,
  isLogged: state.Auth.isLoggedIn,
});

const actions = (dispatch) => ({
  checkUser: (username, email, pass) =>
    dispatch(checkUser(username, email, pass)),
});

export default connect(mapStateToProps, actions)(Auth);

Auth.propTypes = {
  checkUser: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isPending: PropTypes.bool.isRequired,
};
